.sidebar {
	background: material-color('grey', '50');
	border: 1px solid material-color('grey', '400');
	border-radius: $radius;
	padding: 20px;
	margin-bottom: 50px;
	h2 {
		font-size: 26px;
	}
	
	.video-description {
		margin-top: 20px;
	}
	.sidebar-inner {
	    // height: 800px;
	    padding: 20px;
	    width: 100%;
	    background: #fff;
	    border: 1px solid #ccc;
	    border-radius: 0 0 4px 4px;
	    border-top: none;
	}
	label {
		font-weight: bold;
	}
	h4 {
		background: material-color('light-blue', '800');
		padding: 5px 20px;
		margin: 0;
		color: #fff;
		text-shadow: -1px -1px rgba(#000,.3);
		border-radius: 4px 4px 0 0;
		// border: 1px solid #ccc;
		// border-bottom: none;
	}
}

.profile-img-container {
	display: flex;
	justify-content: space-between;
	.btn-container {
		display: flex;
		flex-direction: column;
		.btn {
			margin-bottom: 10px;
		}
	}
}

.profile-image {
	display: block;
	background: material-color('grey', '100');
	border: 1px solid material-color('grey', '400');
	padding: 5px;
	width: 160px;
	height: 180px;
	border-radius: $radius;
	margin-bottom: 20px;
}


.form-footer {
	.btn {
		float: right;
	}
}