.profile-preview {

	.profile-preview-inner {
		margin-top: 15px;
	}

	.profile-preview-text {
		position: relative;

		img {
			margin-top: 10px;
			font-size: 50px;
			position: absolute;
			left: -80px;
			top: -60px;
			width: 80px;
			height: auto;
			transform: rotate(15deg);
		}
	}

	margin-top: 20px;
	background: material-color('grey', '100');
	padding: 20px;
	border-radius: $radius;
	.profile-preview-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 10px;
		width: 230px;
		background: #fff;
		@include card(1);
		border-radius: 4px;
		.agent-img {
			width: 150px;
		    height: 150px;
		    overflow: hidden;
		    border-radius: 4px;
		    margin-bottom: 5px;
		    align-self: center;
		    border-radius: 50%;
		    border: 1px solid #444;

		}
		span {
			display: flex;
			align-items: center;
			font-size: 12px;
			i {
				font-size: 14px;
				margin-right: 3px;
			}
			a {
				display: block;
				text-align: center;
				width: 100%;
				color: material-color('light-blue', '700');
			}
		}
		img {
			// border-radius: 50%;
			// width: 150px;
		 //    height: 150px;
		}
		.btn {
			margin-top: 10px;
			padding: 2px;
		}
	}

	.arrow-holder {
		background: #eee;
		// padding-top: 50px;

		i {
			font-size: 50px;
		}
	}
}