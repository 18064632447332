$lt_blue: #e1ebf5;
$blue: #d7dbde;

$body_color: #28323c;


$radius: 3px;

@mixin featured_font(){
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin body_font(){
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@mixin rowMachine($numPerRow, $margin) {
  width: ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);
  &:nth-child(n) {
    margin-bottom: $margin;
    margin-right: $margin;
  }
  &:nth-child(#{$numPerRow}n) {
    margin-right: 0;
    margin-bottom: 0;
  }
}


@mixin tsd($trans){
  text-shadow: -1px -1px rgba(#000,$trans);
}

@mixin tsl($trans){
  text-shadow: 1px 1px rgba(#fff,$trans);
}


@mixin gradient($start, $end){
  background: $start;
      background-image: -moz-linear-gradient(top, $start 0%, $end 100%);
      background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $start), color-stop(100%, $end));
      background-image: -webkit-linear-gradient(top, $start 0%, $end 100%);
      background-image: -o-linear-gradient(top, $start 0%, $end 100%);
      background-image: -ms-linear-gradient(top, $start 0%, $end 100%);
      background-image: linear-gradient(to bottom, $start 0%, $end 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF'$start, endColorstr='#FF'$end,GradientType=0 );
}

/// Gives a card depth effect.
/// @param {Number} $depth - depth level (between 1 and 5)
/// @link http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality Google Design
/// @requires {function} top-shadow
/// @requires {function} bottom-shadow
@mixin card($depth) {
  @if $depth < 1 {
    box-shadow: none;
  } @else if $depth > 5 {
    @warn "Invalid $depth `#{$depth}` for mixin `card`.";
  } @else {
    box-shadow: bottom-shadow($depth), top-shadow($depth);
  }
}


/// Computes a top-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function top-shadow($depth) {
  $primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
  $blur: nth(1.5 3 10 14 19, $depth) * 4px;
  $color: rgba(black, nth(.12 .16 .19 .25 .30, $depth));

  @return 0 $primary-offset $blur $color;
}

/// Computes a bottom-shadow for a card effect.
/// @param {Number} $depth - depth level
/// @return {List}
@function bottom-shadow($depth) {
  $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
  $blur: nth(1 3 3 5 6, $depth) * 4px;
  $color: rgba(black, nth(.24 .23 .23 .22 .22, $depth));

  @return 0 $primary-offset $blur $color;
}



@mixin media-breakpoint-custom($point) {
  @if $point == mini {
    @media (max-width: 650px) { @content; }
}


  // Main Media Queries
  @else if $point == xxs {
    @media only screen
    and (max-width: 365px) { @content; }
  }
// Other Media Queries

  @else if $point == ipad-portrait {
    @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px) 
    and (orientation : portrait)
    and (-webkit-min-device-pixel-ratio: 1)  { @content; }
  }
  // iPhone 5 portrait
  @else if $point == iphone5-port {
    @media only screen 
    and (min-device-width : 320px) 
    and (max-device-width : 568px) 
    and (orientation : portrait) { @content; }
  }
  // iPhone 5 landscape
  @else if $point == iphone5-land {
    @media only screen 
    and (min-device-width : 320px) 
    and (max-device-width : 568px) 
    and (orientation : landscape) { @content; }
  }
  @else if $point == tablet-portrait {
    @media only screen 
    and (min-width : 200px) 
    and (max-width : 992px) { @content; }
  }
  @else if $point == xs-ls {
    @media only screen 
    and (max-width : 420px) { @content; }
  }
  
  @else if $point == ip5 {
    @media only screen 
    and (min-device-width : 320px) 
    and (max-device-width : 568px) { @content; }
  }

}
