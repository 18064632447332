.credits {
	display: flex;
	justify-content: space-between;
	.credit-box {
		position: relative;
		width: 49%;
		// border: 1px solid material-color('light-blue', '900');
		background: material-color('light-blue', '50');
		border-radius: $radius;
		display: flex;
		flex-direction: column;
		// height: 200px;
		// justify-content: space-between;
		align-items: center;
		background: linear-gradient(material-color('light-blue', '500'),material-color('light-blue', '900'));
		overflow: hidden;
		@include card(1);
	}
	
	.credit-digit {
		position: relative;
		z-index: 10;
		color: #000;
		text-shadow: 1px 1px rgba(#fff, .3);
		background: material-color('light-blue', '600');
		@include card(1);
		font-size: 42px;
		font-weight: 700;
		padding: 20px;
		border-radius: 50%;
		width: 65px;
		height: 65px;
		line-height: 1;
		border: 5px solid material-color('light-blue', '100');
		display: flex;
		justify-content: center;
		align-items: center;
	}
	h4 {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 10;
		margin: 0;
		background: material-color('light-blue', '500');
		width: 100%;
		height: 50px;
		line-height: 1;
		// font-weight: 700;
		padding: 10px;
		color: #fff;
		text-shadow: -1px -1px rgba(#000,.2);
		border-bottom:1px solid material-color('light-blue', '700');
		i {
			// @include card(1);
			color: material-color('yellow', '900');
			color: #000;
	        background: material-color('yellow', '700');
			color: material-color('light-blue', '900');
			text-shadow: none;
			border-radius: 50%;
			padding: 5px;
			font-size: 20px;
			margin-right: 5px;
		}
	}
	p {
		position: relative;
		z-index: 10;
		color: #fff;
		margin-bottom: 15px;
		font-size: 14px;
	}
	.line {
		position: relative;
		z-index: 20;
		width: 100%;
		border-top: 1px solid material-color('light-blue', '300');
	}
	.digit-container {
		width: 100%;
		height: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 10px;
		margin-bottom: 15px;
		&:before {
			position: absolute;
			top: -150px;
			left: 50%;
			margin-left: -800px;
			z-index: 0;
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 300px 800px 0 800px;
			border-color: material-color('light-blue', '500') transparent transparent transparent;
		}
	}
	.credit-btns {
		// position: absolute;
		// right: 10px;
		// bottom: -10px;

		padding: 0;
		margin: 0;
		list-style: none;
		display: flex;
		margin-bottom: 20px;
		li {
			margin: 0 5px;
			.btn-success {
			background: material-color('light-blue', '500');
				border: none;
				// @include card(1);
				&:hover {
					background: material-color('light-blue', '600');
				}
			}
			.btn {
				padding: 5px 15px;
			}
		}
	}

}

	