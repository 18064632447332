@import "../wp-gulp/node_modules/sass-material-colors/sass/_sass-material-colors.scss";
@import "bootstrap-4.1.3/bootstrap";
@import "./partials/mixins";

@import "./partials/sidebar";

@import "./partials/credits";

@import "./partials/profile-preview";

html {
	position: relative;
  	min-height: 100%;
}

.top-header {
	&:before {
		position: absolute;
		top: 10px;
		left: 0;
		z-index: 9000;
		color: rgba(#000, .2);
		@include media-breakpoint-up(xs) {
			// display: none;
			left: auto;
			right: 5px;
			content: 'xs';
		}
		@include media-breakpoint-up(sm) {
			content: 'sm';
		}
		@include media-breakpoint-up(md) {
			left: 5px;
			right: auto;
			content: 'md';
		}
		@include media-breakpoint-up(lg) {
			content: 'lg';
		}
		@include media-breakpoint-up(xl) {
			content: 'xl';
		}
		@include media-breakpoint-up(xxl) {
			content: 'xxl';
		}
	}
}

body {
	margin-bottom: 50px;
	font-size: 14px;
}

td, th {
	font-size: 14px;
}

.container {
	@include media-breakpoint-only(xxl){
		width: 1300px;	
	}
}
.top-header{margin: 20px 0px;}
.inner-container{width:90%;margin:auto;}
.navbar-custom{
    z-index: 100;
    width: 100%;
    box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12);
    position: relative;
    background: #BA1127;
    border-radius:0;
    border: none;
    min-height: 40px;
    max-height: 40px;

}

.navbar-custom .navbar-nav > li > a{
    color:#fff !important;

    text-decoration: none;
    display: block;
    text-decoration: none;

    height: 40px;
    line-height: 40px;
    font-size: 16px;
    padding: 0 15px;

    font-style: normal;
    font-weight: 400;
    transition: all .25s;
}
.navbar-custom .navbar-nav > li > a:hover,.navbar-custom .navbar-nav > li > a:focus{
    color: #fff;
    text-decoration: none;
    background: #e91531;
}

.navbar-custom .navbar-nav > li.active > a,.navbar-custom .navbar-nav > li.active > a:hover{
    background: rgba(0, 0, 0, 0.1);
}

.navbar-custom .navbar-collapse{padding-left: 0px;}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:hover, .navbar-default .navbar-nav>.open>a:focus {
    color: #fff;
    background: #e91531;
}
.user-info-header{background: #eee;text-align: center}

.main{margin-top: 600px;}

.content {
	margin-top: 40px;
}

.styled-form {
    background: material-color('grey', '50');
    border: 1px solid material-color('grey', '400');
    border-radius: $radius;
    padding: 10px 20px;
}

.modal-header{background-color: #BA1127;color: #fff;}
.modal .close{color: #fff;opacity: 1;}

.autocomplete-suggestions { border: 1px solid #999; background: #FFF; overflow: auto; }
.autocomplete-suggestion { padding: 2px 5px; white-space: nowrap; overflow: hidden; }
.autocomplete-selected { background: #F0F0F0; }
.autocomplete-suggestions strong { font-weight: normal; color: #3399FF; }
.autocomplete-group { padding: 2px 5px; }
.autocomplete-group strong { display: block; border-bottom: 1px solid #000; }




footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	background: material-color('grey', '400');
	.footer-inner {
		height: 50px;
		color: material-color('grey', '100');
		a {
			color: material-color('grey', '100');
		}
	}
}




.zip-table {
	background: #fff;
	margin-top: 20px;
	.btn {
		padding: 3px 10px;
	}
	td, th {
		vertical-align: middle;
	}
}


.btn-yellow {
	background: material-color('yellow', '700');
	color: material-color('grey', '900');
	border: none;
	&:hover {
		color: material-color('grey', '900');
		text-decoration: none;
		// @include card(2);
		background: material-color('yellow', '800');
	}
}


.page-header {
	small {
		font-size: 14px;
	}
}

.btn-red {
	color: #fff;
    background-color: #BA1127;
    border-color: #8b0d1d;
    width: 100%;
    &:hover {
    	color: #fff;
		background: #dd142e;
		border-color: #ba1127;
    }
    &:focus {
    	outline: none;
    	box-shadow: none;
    }
}